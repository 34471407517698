.footer
{
    margin-top: 6%;
    font-family: orbitron;   
}
.footer-want
{
    font-family: "PP Telegraf";
    width: 100%;
    padding: 5%;
    border-radius: 20px;
    background: url('../../public/assets/images/Logo Background Green (1) 1.png') center center/cover, linear-gradient(to right, rgb(6, 255, 106), rgb(152, 246, 45));
}
.footer-want h2
{
    font-size: 2.5rem;    
}
.footer-menu
{
    padding-top: 8%;
    padding-bottom: 5%;
    font-family: "PP Telegraf";
}
.footer h6
{
    color: #04F280;
}
.footer p
{
    color: white;
    text-decoration: none;
    font-family: PP Telegraf;
}
.text-reset 
{
    text-decoration: none;
}
.footer-input
{
    width: 100%;
    background-color: #2a2a2a61;
    height: 50px;
    border: 1px;
    color: white;
    padding: 5%;
}
.f-lading-button{
    border-radius: 10px;
    font-family: orbitron;
    margin-top: 3%;
    margin-bottom: 3%;
    background: linear-gradient(to right, rgb(0, 255, 128), rgb(125, 245, 21));
    color: black;
}
.footer-menu-heading
{
    font-family: "PP Telegraf";
}
.footer-menu-heading h2
{
    color: #04F280;
    font-family: "PP Telegraf";
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 5%;
}
.footer-menu-heading h3
{
    color: rgba(212, 212, 212, 1);
    font-size: 20px;
    line-height: 1.8;
}
.footer-menu-heading h3 a
{
    color: rgba(212, 212, 212, 1);
    font-size: 20px;
    line-height: 1.8;
    text-decoration: none;
}
.input-text
{
    width: 100%;
    padding: 2%;
    border-radius: 5px 5px 5px 0px;
    border: 1px solid rgba(255, 255, 255, 0.00);
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(10px);
    color: white;
    border: 1px solid #00000000;
}
.input-submit {
    width: 100px;
    padding: 5%;
    border-radius: 5px;
    background: var(--lynx-gradient, linear-gradient(180deg, #9DED69 0%, #04F280 70.21%));
    box-shadow: 3px -3px 30px 0px rgba(157, 237, 105, 0.25);
    border: 1px solid #f5f5dc00;
}

.input-submit::before {
    content: "";
    border: 1px solid greenyellow;
}

.input-submit:focus {
    outline: none;
    border: transparent;
}
.footer-input-button
{
    height: 50px;
    border-radius: 5px;
    background: var(--lynx-gradient, linear-gradient(180deg, #9DED69 0%, #04F280 70.21%));
    box-shadow: 3px -3px 30px 0px rgba(100, 234, 10, 0.25);
    display: inline-flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
@media only screen and (max-width: 600px) {
    .footer
    {
        min-height: auto;
    }
    .footer-want h2
    {
        font-size: 1.2rem;
    }
    .footer h6
    {
        font-size: 0.5rem;
    }
    .footer p
    {
        font-size: 0.5rem; 
    }  
    .footer-input
    {
        width: 100%;
        height: 20px;
        padding: 5%;
        font-size: 0.5rem;
    }
    .f-lading-button {
        margin-top: 20%;
        font-size: 12px;
    }
    .footer-input-button
    {
        display: none;
    }
}


