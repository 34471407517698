.nft-debit-button
{
    width: 100%;
    margin: auto;
}
.physical-card-text
{
    text-align: center;
    font-family: orbitron;
}
.physical-card-text h3
{
    color: #ACC1B7;
    font-family: "PP Telegraf";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    font-size: 1.1rem;
}
.physical-card-text h2
{
    color: #04F280;
    font-size: 3rem;
}

.benefits-cards
{
    height: auto;
    width: 100%;
    padding-bottom: 10%;
    background-image: url('../../public/assets/images/Rectangle 44.png');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 400px;
}
.benefits-section
{
    padding-top: 5%;
    padding-bottom: 1%;
}
/* .benefits-cards img
{
    width: 100%;
} */
.benefits-content , .benefits-cards
{
    min-height: 350px;
    background-color: #172826;
    border-radius: 20px 20px 20px 20px;
}
.content-color{
    background-color: #172826;
}
.benefits-content h1
{
    color: #04F280;
    font-size: 25px;
    padding: 6%;
}
.benefits-content p
{
    color: white;
    padding: 6%;
}

.benefits-content-p
{
    min-height: 200px;
 
}
.benefits-content-header
{
    min-height: 100px;
}
.benefits-content-o p
{
    width: 80%;
    text-align: right;
    color: white;
}
.hidden {
    display: none;
  }
  .ecrow-model
  {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .ecrow-model
  {
    text-align: center;
  }
  .ecrow-model-heading h3
    {
        color:#ACC1B7 ;
        font-size: 22px;
       font-family: PP Telegraf; 
       font-style: normal;
       font-weight: 600;
       line-height: normal;
       text-transform: uppercase;
    }
    .ecrow-model-heading h2
    {
        font-family: orbitron;
        color: #04F280;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px;
    }
    .ecrow-box-data
    {
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .ecrow-box-content
    {
        position:relative;
    }
    .ecrow-box-content
    {
        background-position: center;
        background-size: cover;
        width: 100%;
        height: auto;
        min-height: 350px;
        border-radius: 20px 20px 20px 20px;
        margin-bottom: 5%;
    }
    .ecrow-box-content-data
    {
        padding: 10%;
    }
    .ecrow-box-content-data h2
    {
        text-align: left;
        font-family: orbitron;
        font-size: 48px;
        color: rgba(243, 255, 249, 0.50);
        font-weight: 80;
    }
    .ecrow-box-content-data h3
    {
        text-align: left;
        color: white;
        font-family: orbitron;
        padding-top: 2%;
    }
    .ecrow-box-content-data p
    {
        padding-top: 2%;
        text-align: left;
        color: rgba(243, 255, 249, 0.50);
        font-size: 20px;
    }
    .safe-secure
    {
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .safe-secure-heading
    {
        padding-top: 5%;
    }
    .safe-secure-heading h2
    {
        color: white;
        padding-top: 60%;
        font-family: "PP Telegraf";
        font-size: 60px;
    }
    .safe-secure-box
    {
        min-height: 400px;
        border-radius: 20px;
        border: 0.82px solid rgba(243, 255, 249, 0.10);
        background: #151A22;
        backdrop-filter: blur(24.5928955078125px);
        width: 90%;
        margin: auto;
    }
    .safe-secure-data
    {
        padding: 6%;
    }
    .form-heading
    {
        font-size: 18px;
        color: white;
        font-family: "PP Telegraf";
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .form-heading-input input
    {
        width: 100%;
        background-color: #00000000;
        border: 0px;
        border-bottom: 1px solid rgb(93, 255, 48);
        color: white;
        font-family: "PP Telegraf";
    }
    .form-heading-input input:active
    {
        border: 1px solid black;
    }
    .form-heading-row
    {
        padding-top: 3%;
    }
    .create-contract
    {
        margin-top: 5%;
        border-radius: 10px;
        background: linear-gradient(180deg, #9DED69 0%, #04F280 70.21%);
        display: inline-flex;
        padding: 18px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .form-heading-input text
    {
        color: white;
        font-family: "PP Telegraf";
    }
    .mobile-desply
    {
        display: block;
    }
    .destop-disply
    {
        display: none;
    }
    .header-text
    {
        margin-top: 20%;
    }
    .img-header-nft
    {
        width: 70%;
        margin: auto;
        margin-top: 10%;
        margin-bottom: 10%;
        position: relative;
    }
    .header-texts
    {
        color: white;
    }
@media only screen and (max-width: 600px) {
    .img-header-nft {
        width: 60%;
        margin: auto;
        margin-top: 10%;
        margin-bottom: 0%;
        position: relative;
    }
    .physical-card-text h2 {
        color: #04F280;
        font-size: 1.5rem;
    }
    .benefits-content, .benefits-cards {
        min-height: 300px;
    }
    .benefits-cards {
        background-image:none;
    }
    .physical-card-text h3 {
        font-size: 1rem;
    }
    .image-post {
        top: 10%;
    }
    .nft-debit-button
    {
        width: 100%;
    }
    .benefits-cards
    {
        margin-bottom: 10%;
    }
    .ecrow-model-heading h3
    {
        font-size: 18px;
    }
    .ecrow-model-heading h2
    {
        font-size: 30px;
    }
    .ecrow-box-content-data h2
    {
        text-align: center;
        font-size: 30px;
    }
    .ecrow-box-content-data h3
    {
        font-size: 23px;
    }
    .ecrow-box-content-data p {
        font-size: 18px;
    }
    .safe-secure-heading h2
    {
        margin-top: 0%;
        text-align: center;
        padding-top: 0%;
        font-size: 30px;
    }
    .create-contract {
        padding: 10px;
        margin-top: 12%;
    }
    .form-heading {
        margin-top: 8%;
    }
    .form-heading {
        font-size: 14px;
    }
    .safe-secure-data
    {
        padding-top: 10%;
        padding-bottom: 10%;
    }
    .safe-secure-box
    {
        width: 100%;
        margin-bottom: 10%;
    }
    .physical-card-text
    {
        padding-top: 10%;
    }
    .ecrow-box-content
    {
        min-height: 260px;
    }
    .mobile-desply
    {
        display: none;
    }
    .destop-disply
    {
        display: block;
    }
    .safe-secure-heading
    {
        padding-top: 0%;
        padding-bottom: 10%;
    }
    .web3-escrow
    {
        text-align: center;
    }
    .web3-escro-img
    {
        padding-top: 5%;
        width: 60%;
        margin-bottom: 0%;
    }
    .web-physical-card-text
    {
        padding-top: 0%;
    }
    .fuxion-pay-p
    {
        font-size: 1.1rem;
        font-family: PP Telegraf;
        text-align: justify;
        color: white;
    }
}