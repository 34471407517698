.getlynx-section
{
    min-height: 40rem;
}
.getlynx
{
    background-image: url("../../public/assets/images/Token\ 1.png"); 
  background-color: #cccccc; 
  height: 40rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  padding-top: 10%;

}
.getlynx h2
{
    font-size: 3rem;
    color: #ffffff;
    font-family: orbitron;
}
.getlynx-span-text
{
    color: #04F280;
}
.getlynx-p
{
    width: 40%;
    color: white;
    font-family: PP Telegraf;
    font-size: 1.2rem;
}
.get-debit-button
{
    width: 20%;
}
@media only screen and (max-width: 600px) {

    .getlynx
    {
        height: 20rem;
    }
    .getlynx h2
    {
        font-size: 1.5rem;
    }
    .getlynx-p
    {
        width: 50%;
        font-size: 0.7rem;
    }
    .get-debit-button
    {
        width: 40%;
    }
}