.stacking
{
    min-height: 700px;
    margin-bottom: 2%;
    background-image: url('../../public/assets/images/Lynx Staking Image PNG 1.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
}
.stacking h2
{
    color: #FDDD3F;
    padding-top: 10%;
    font-size: 3.5rem;
}
.stacking h2 span
{
    color: white;
}
.stacking p
{
    color: white;
    font-size: 1rem;
    width: 60%;
}
.stake-dash
{
    min-height: 600px;
}
.button-staking
{
    border:1px solid yellow;
    width: 30%;
    margin: auto;
    padding: .1%;

}
.button-staking button
{
 width: 50%;
 font-size: 1.2rem;
 background-color: #FDDD3F;
 color: black;
}
.total_staked
{
    margin-top: 3%;
    background-color: rgba(33, 37, 33, 0.246);   
     padding: 3%;
     font-family: orbitron;
}
.total-stakes-data 
{
    padding-top: 10%;
    padding-bottom: 10%;
    width: 100%;
    margin: auto;
}
.total-stakes-data h3
{
    color: white;
    text-align: left;
    font-size: 1.5rem
}
.total-stakes-data h2
{
    color: #2ae72e;
    font-weight: bolder;
    font-size: 3.5rem;
    text-align: center;
}
.total-stakes-data h4
{
    color: #55fd3f;
    text-align: right;
    font-weight: lighter;
}
.total_staked_graph
{
    height: 225px;
}
.choose-data-container
{
    padding: 4%;
}
.choose_arp
{
    min-height: 400px;
    margin-top: 5%;
    margin-bottom: 5%;
    background: linear-gradient(180deg, rgba(253, 221, 63, 0.1) 0%, rgba(255, 255, 254, 0) 99.99%, rgba(255, 255, 255, 0) 100%);
    border-radius: 2%;
}
.choose-data
{
    width: 17%;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.choose-data-select
{
    width: 12rem;
    height: 2rem;
    text-align: center;
    border-radius: 10px;
    background-color: #04F280;
    font-size: 15px;
}
.choose-data-box
{
    min-height: 250px;
    border-radius: 10px;
    width: 100%;
    background-color: rgb(107 168 144 / 19%);
    text-align: center;
    padding: 12%;
}
.choose-data-box h5
{
    font-size: 1.3rem;
    font-weight: bold;
    color: white;
}
.choose-data-box h1
{
    font-size: 4rem;
    font-family: orbitron;
    color: #04F280;
}
.choose-data-input {
    padding-top: 1%;
    padding-bottom: 1%;
    min-height: 50px;
  }
  
  .choose-data-input h3 {
    font-size: 24px;
    color: white;
    font-family: jeko-semibold;
  }
  
  .choose-input-box {
    border: 1px solid #04F280;
    height: 40px;
  }
  
  .choose-input-box-number {
    width: 100%;
    height: 38px;
    border: 0px;
    background-color: #1668af00;
    color: white;
    margin: auto;
    font-size: 18px; /* Adjust font size */
  }
  
  .choose-input-box-p {
    color: white;
    font-size: 14px; /* Adjust font size */
    margin: auto;
    font-family: PP Telegraf;
  }
  
  .box-pp {
    color: white;
    font-size: 14px; /* Adjust font size */
    margin: auto;
    line-height: 2rem;
  }
  
  /* Media queries for responsiveness */
  @media only screen and (max-width: 768px) {
    .choose-input-box-p,
    .box-pp {
        font-size: 10px;
        width: 5%;
    }
    .choose-data-box h1
    {
        font-size: 1rem;
    }
    .choose-data-box {
        min-height: 100px;
    }
    .choose-data-box h5 {
        font-size: 0.7rem;
    }
    .choose-data {
        width: 50%;
    }
    .total-stakes-data h2 {
        font-size: 1.5rem;
    }
    .total-stakes-data h3 {
       
        font-size: 1rem;
    }
    .stacking
    {
        min-height: auto;
    }
    .stacking h2 
    {
        font-size: 1.5rem;
    }
    .stacking p 
    {
        font-size: .5rem;
    }
    .response-img-header-one
    {
        width: 50%;
    }
    
  }
  
  @media only screen and (max-width: 576px) {
    .choose-input-box-number {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  }
  