@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');
.header-token
{
    margin-top: 5%;
    margin-bottom: 5%;
}
.token-heading-data
{
    padding-top: 10%;
}
.token-heading-data h2
{
    color: #04F280;
    font-family: Orbitron;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 1; /* 57.6px */
}
.token-heading-data h2 span
{
    color: white;
}
.token-heading-p
{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 80%;
}
.token-heading-p p
{
    font-size: 18px;
    color: white;
    font-family: "PP Telegraf";
    font-style: normal;
    text-align: justify;
    font-weight: 400;
}
.button-right-div
{
    float: left;
}
.col-3-d
{
    width: 30%;
}
.btn-border
{
    color: white;
    border-radius: 10px;
    border: 1px solid #04F280;
    display: inline-flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 150px;
}
.token-main-img img
{
    width: 50%;
    float: none;
}
.sub-loy
{
    margin-top: 5%;
    margin-bottom: 5%;
}
.button-option
{
    width: 45%;
    margin: auto;
    background-color: #2d2f2e;
    padding: 0.7%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    border-radius: 10px;

}
.button-loy-one
{
    text-align: center;
    width: 50%;
    padding-top: 3%;
    border-radius: 10px;
    background: var(--lynx-gradient, linear-gradient(180deg, #9DED69 0%, #04F280 70.21%));
}
.button-loy-one p
{
    color: black;
    font-family: "PP Telegraf";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.button-loy-two
{
    text-align: center;
    width: 50%;
    padding-top: 3%;
    /* border: 1px solid rgba(243, 255, 249, 0.05);
    background: rgba(243, 255, 249, 0.15); */
}
.button-loy-two p 
{
    color: white;
    font-family: "PP Telegraf";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.lock-content
{
    margin-top: 10%;
    margin-bottom: 5%;
}
.lock-heading h2
{
    color: #F3FFF9;
    font-family: Orbitron;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
}
.lock-heading span
{
    color: #9DED69;
}
.lock-p
{
    padding-top: 5%;
    width: 80%;
    text-align: justify;
}
.lock-p p 
{
    color: white;
    font-size: 18px;
}
.inproject
{
    margin-top: 5%;
    margin-bottom: 5%;
}
.indivdual-box
{
    width: 100%;
    height: auto;
    margin: auto;
    padding: 10%;
    background-repeat: no-repeat;
}
.header-ind h2
{
    color: #F3FFF9;
    font-family: Orbitron;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;    
}
.indivdual-box
{
    position: relative;
}
.indivdual-img
{
    position: absolute;
    width: 80%;
}
.header-ind
{
    position: relative;
    color: white;
}
.indivdul-dontent
{
    padding: 10%;
}
.ind-img-text
{
    width: 50%;
    color: #F3FFF9;
    font-family: Orbitron;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
}
.h-imgs
{
    padding-top: 10%;
    width: 50%;
    margin-left: 30px;
}
.indivdul-p-t
{
    padding-left: 5%;
    padding-top: 15%;
    width: 90%;
}
.indi-coiming
{
    padding-left: 5%;
    padding-top: 15%;
    text-align: right;
}
.lading-button-s
{
        border: 1px solid;
        border-radius: 10px;
        font-family: PP Telegraf;
        margin-top: 3%;
        margin-bottom: 3%;
        background: var(--lynx-gradient, linear-gradient(180deg, #9DED69 0%, #04F280 70.21%));
        color: black;
        /* padding: 15px; */
        gap: 10px;
        width: 90%;
}
.btn-border:hover
{
    color: white;
}
.loyalty-data-hold
{
    margin-top: 10%;
    margin-bottom: 10%;
}
.loyalty-data-hold-header h2
{
    color: #F3FFF9;
    font-family: Orbitron;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.loyalty-data-hold-header h2 span
{
    color: #9DED69;
}
.loyalty-data-hold-p
{
    margin-top: 10%;
}
.loyalty-data-hold-p p 
{
    color: #FFF;
    font-family: "PP Telegraf";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px; 
}
.token-reward
{
    width: 70%;
    margin: auto;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
}
.space-reward
{
    margin-top: 15%;
}
.token-reward-heading h2
{
    color: #9DED69;
    font-family: Orbitron;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
}
.token-reward-p p
{
    margin-top: 3%;
    color: #FFF;
    text-align: center;
    font-family: "PP Telegraf";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
}
.reward-source
{
    margin-top: 10%;
    margin-bottom: 10%;
}
.reward-source
{
    width: 90%;
    margin: auto;
}
.reward-source-img img
{
    width: 80%;
    float: left;
}
.reward-source-text
{
    padding-top: 20%;
}
.reward-source-text h2
{
    color: #9DED69;
    font-family: Orbitron;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /*  */
}
.reward-source-p
{
    margin-top: 5%;
}
.reward-source-p p 
{
    font-family: "PP Telegraf";
    color: white;
    padding-left: 4%;
}
.reward-source-p p span
{
    padding: 1%;
}
.Scenarios
{
    margin-top: 10%;
}
.Scenarios-heading h2
{
    color: #9DED69;
    font-family: Orbitron;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 48px */
    text-align: center;
}
.Scenarios-box
{
    position: relative;
    width: 100%;
    height: auto;
    margin: auto;
    background-repeat: no-repeat;
}
.Scenarios-box img
{
    position: relative;
    width: 100%;
}
.scen-content
{
    position: absolute;
}
.indivdual-imge
{
    position: absolute;
    width: 100%;
}
.indivdul-con-t
{
    padding-top: 8%;
}
.indivdul-con-t p
{
    color: #F3FFF9;
    font-family: Orbitron;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding-top: 5%;
}
.indivdual-box-s
{
    position: relative;
    width: 100%;
    height: auto;
    margin: auto;
    background-repeat: no-repeat;
}
.indivdul-button-s {
    display: inline-flex;
    /* padding: 6px; */
    padding-top: 6%;
    padding-bottom: 6%;
    padding-left: 6%;
    padding-right: 6%;
    border-radius: 10px;
    background: #1C1C2B;
    color:#04F280;
    border: 1px #1C1C2B;
}
.Scenarios-heading
{
    margin-top: 5%;
    margin-bottom: 5%;
}
.indivdul-p-t-t {
    padding-left: 0%;
    padding-top: 8%;
    font-size: 18px;
}
.h-imgss {
    padding-top: 10%;
    width: 65%;
    margin-left: 30px;
}
/*
* Mobile screen Css
*/
@media only screen and (max-width: 600px) {
    .h-imgs {
        margin-left: 30px;

    }
    .token-heading-data h2
    {
        font-size: 30px;
        text-align: center;
    }
    .token-heading-p p
    {
        font-size: 15px;
        text-align: center;
    }
    .token-main-img img
    {
        width: 50%;
        float: none;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .col-3-d
    {
        text-align: center;
    width: 50%;
    }  
    .ind-img-text
    {
        font-size: 18px;
    }
    .indivdual-img {
        position: absolute;
        width: 90%;
    } 
    .indi-coiming {
        padding-left: 0%;
        padding-top: 0%;
        text-align: right;
    }
    .indivdul-p-t {
        padding-left: 5%;
        padding-top: 10%;
        
    }
    .indivdual-box
    {
        padding: 3%;
    }
    .btn-border
    {
        padding: 7px;
        font-size: 18px;
        margin-top: 2%;
        width: 90%;
    }
   
    .button-option
    {
        width: 90%;
    }
    .lock-heading h2
    {
        font-size: 25px;
        text-align: center;
        margin-bottom: 10%;
    }
    .lock-p
    {
        width: 100%;
        padding-top: 0%;
        text-align: center;
        font-size: 16px;
    }
    .button-loy-one p
    {
        font-size: 16px;
    }
    .button-loy-two p {
       
        font-size: 16px;
    }
    .button-loy-one
    {
        height: 60px;
        padding-top: 6%;
    }
    .button-loy-two
    {
        height: 60px;
        padding-top: 4%;
    }
    .loyalty-data-hold-header h2
    {
        font-size: 25px;
        text-align: center;
        margin-bottom: 10%;
    }
    .loyalty-data-hold-p {
        margin-top: 5%;
    }
    .token-reward-heading h2
    {
        font-size: 30px;
    }
    .token-reward
    {
        width: 100%;
    }
    .space-reward {
        margin-top: 10%;
    }
    .reward-source-text h2
    {
        font-size: 20px;
    }
    .reward-source-p p
    {
        padding-left: 0%;
        font-family: "PP Telegraf";
    }
    .Scenarios-heading h2 {
        font-size: 20px;
    }
    .box-s-p
    {
        padding-top: 8%;
    }
    .indivdual-box-s {
        padding-bottom: 5%;
    }
}
