@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.login-page {
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin: auto;
    padding: auto;
}

.login-section {
    width: 40%;
    height: auto;
    flex-shrink: 0;
    border-radius: 16px;
    background: #161917;
    /* margin and padding are not needed as flexbox handles centering */
}
.login-logo
{
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
}
.logo-p
{
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
}
.logo-p-text
{
    color: #DFDFDF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.logo-input
{
    width: 60%;
    margin: auto;
    padding-top: 2%;
    padding-bottom: 2%;
}
.logo-input-text
{
    width: 100%;
    border-radius: 12px;
    border: 0.7px solid #797979;
    background: rgba(217, 217, 217, 0.00);
    color: #797979;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 3%;
}
.logo-input-button
{
    border-radius: 12px;
    background: linear-gradient(85deg, #04F280 -26.2%, #9DED69 112.11%);
    width: 100%;
    border-radius: 12px;
    color: #161917;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 3%;
}
.or-div
{
    padding-top: 3%;
    padding-bottom: 3%;
}
.line
{
    
    margin: auto;
    margin-top: 5%;
    width: 80%;
    height: 0.7px;
    background: #797979;
}
.or-p
{
    color: #797979;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
}
.connect-your-wallet
{
    padding-top: 2%;
    padding-bottom: 2%;
    width: 60%;
    margin: auto;
}
.connect-your-wallet-button
{
    border-radius: 12px;
    border: 0.7px solid #04F280;
    background: rgba(217, 217, 217, 0.00);
    width: 100%;
    color: #F9FAFB;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 5%;
}
.support-by-login
{

    padding-top: 3%;
    padding-bottom: 3%;
}
.support-by-login p
{
    color: #FFF;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
}
@media (min-width: 400px) and (max-width: 1200px) {
    .login-section {
        width: 90%;
    }
    .login-page
    {
        height: 100vh;
    }
}