@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

@font-face {
  font-family: 'PP Telegraf';
  src: url('../pp/PPTelegraf-Regular.otf') format('opentype');
}
.teamone
{
  width: 30%;
}

.lading-header
{
 background-image: url('../../public/assets/images/pngkey\ 1.png');
 background-size: cover;
 background-position: center;
 min-height: 500px;
 height: max-content;
}
.support-by
{
  height: 40px;
  line-height: 2;
  position: absolute;
  background: linear-gradient(90deg, #04F280 0%, #9DED69 100%);
  color: black;
  font-family: orbitron;
  width:  160px;
  height: 31px;
  margin-top: -3.7%;
  font-weight: 400;
  text-align: center;
  border-radius: 0px 10px 10px 10px;
  box-shadow: -3px 3px 3px 0px rgba(0, 0, 0, 0.25), 3px -3px 3px 0px rgba(12, 17, 22, 0.25);  
}

.lading-details
{
  width: 70%;
  margin: auto;
  padding-top: 5%;
}
.lading-details h2
{
  text-align: center;
  color: white;
  font-size: 4rem;
  font-variant-caps: all-small-caps;
  font-weight: bold;
  font-family: orbitron;
  padding-top: 8%;
  line-height: 120%;
}
.lading-details p 
{
  width: 70%;
  margin: auto;
  font-weight: 800;
  text-align: center;
  line-height: 140%;
  color: white;
  font-size: 1.1rem;
  font-family: PP Telegraf;
  padding-top: 1%;
}
.lading-buttons
{
  padding-top: 2%;
  width: 40%;
  margin: auto;
}
.lading-h2
{
  color: white;
  font-size: 4rem;
  width: 100%;
  margin: auto;
  padding-top: 10%;
  font-variant-caps: all-small-caps;
  font-weight: bold;
  font-family: orbitron;
}

.h-lading-p
{
  background: linear-gradient(91deg, #04F29B 34.87%, #9DED69 84.61%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: orbitron;
}
.lading-p
{
  font-size: 1.1rem;
  width: 100%;
  color: white;
  margin: auto;
  padding-top: 2%;
  padding-top: 2%;
  font-family: PP Telegraf;
}
.lading-button
{
  border-radius: 10px;
  font-family: orbitron;
  margin-top: 3%;
  margin-bottom: 3%;
  background: linear-gradient(to right, rgb(0, 255, 128), rgb(125, 245, 21));
  color: black;
}
.lading-company
{ 
  min-height: 10px;
  padding: 3%;
  background-image: linear-gradient(to right, rgb(18, 28, 23), rgb(14, 33, 24));

  margin-bottom: 5%;
}
.header-menu a
{
  color: white;
  font-family: orbitron;
  text-decoration: none;
}

.card-details
{
  width: 65%;
  margin: auto;
  margin-top: 15%;
  margin-bottom: 10%;
}
.card-details h2
{
  background: #9DED69;
  /* color: #9DED69; */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: orbitron;
}
.card-details p
{
  color: white;
  font-family: PP Telegraf;
}
.card-details-lading-button
{
  border-radius: 10px;
  font-family: PP Telegraf;
  margin-top: 3%;
  margin-bottom: 3%;
  background: var(--lynx-gradient, linear-gradient(180deg, #9DED69 0%, #04F280 70.21%));
  color: black;
  /* padding: 15px; */
  gap: 10px;
}
.lading-token
{
  background: linear-gradient(to right, rgb(6, 255, 106), rgb(190, 225, 11)),
  url('../../public/assets/images/Logo Background Green (1) 1.png');
  background-size: cover; 
  background-blend-mode: overlay; 
  padding: 4%;
  width: 100%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 10%;
}
.lading-token-img
{
  width: 50%;
}
.lading-token h2
{
  font-size: 3rem;
  font-family: orbitron;
  margin-top: 0%;
}
.lading-token p
{
  font-size: 1rem;
  font-family: PP Telegraf;
  width: 70%;
}
.lading-team
{
  width: 100%;
  margin: auto;
  margin-bottom: 5%;
}
.lading-team-name h3
{
  margin-top: 5%;
  font-size: 1rem;
  text-align: center;
  color: #9DED69;
}
.lading-team-type p
{
  margin-top: 5%;
  font-size: .8rem;
  text-align: center;
  color: #ffffff;
  font-family: PP Telegraf;
}
.lading-team-h2
{
  font-size: 40px;
  background: linear-gradient(to right, rgb(3, 255, 129), rgb(62, 204, 49));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: orbitron;
  text-align: center;
  margin-bottom: 5%;
  font-weight: 400;
  color: #9DED69;
}
.lading-social {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 10%;
}
.lading-team-images
{
  text-align: center;
  display: flex;
  margin: auto;
}
.lading-team-images-img
{
  width: 100%;
}
.lading-team-twiiter
{
  width: 50%;
}
.leading-team-social {
  display: flex;
}
.partners
{
  width: 50%;
  margin: auto;
  margin-bottom: 5%;
}
.partners h2
{
  font-size: 2rem;
  color: #04F280;
  font-family: orbitron;
  text-align: center;
  margin-bottom: 5%;
}
.fuxionist
{
  margin-bottom: 5%;
}
.fuxionist h2
{
  font-size: 2rem;
  background: #9DED69;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: orbitron;
  text-align: center;
  margin-bottom: 2%;
}
.fuxionist_p
{
  text-align: center;
  width: 60%;
  font-size: 24px;
  margin: auto;
  color: white;
  margin-bottom: 2%;
  font-family: PP Telegraf;
}
.fuxinist-contact
{
  width: 40%;
  margin: auto;
}
.img-header-one 
{
  width: 50%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
  position: relative; 
}
.img-header-two 
{
  width: 60%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
  position: relative; 
}
.header-one-img 
{
  width: 100%; 
  margin: auto;
}
.image-post {
  width: 120%;
  position: absolute;
  z-index: 1;
  top: 5%;
  left: -10%;
}
.contect-links
{
  width: 100%;
}
.img-part-one
{
  padding-top: 15%;
  width: 100%;
}
.header-one-ladning
{
  position: absolute;
  width: 98%;
  /* object-fit: scale-down; */
  left: 1%;
  right: 0%;
  top: 1%;
}
.img-header-card
{
  width:70%;
  margin-Top:0%;
}
.img-web3-escrow
{
  width: 70%;
}
.support-by-image
{
  width: 50%;
}
/*
* Destop Reponsive screen size
*/
@media only screen and (max-width: 1000px) {
  /* Your CSS rules for iPad portrait mode */
  .fuxionist_p
  {
    font-size: 12px;
  }
  .img-header-one 
  {
      width: 100%;
      margin: auto;
      margin-top: 20%;
      margin-bottom: 5%;
  }
  .lading-details h2 {
    font-size: 3rem;
    text-decoration: none;
  }
  .lading-buttons {
    width: 70%;
    min-width: 70%;
  }
  .card-details
  {
    margin-bottom: 20%;
  }
  .support-by
  {
    width: 26%;
    margin-top: -7%;
    margin-left: -11%;
  }
  .lading-token-img
  {
    width: 80%;
    margin: auto;
    padding-top: 20%;
  }
}
/*
* Mobile Responisve Screen
*/
@media only screen and (max-width: 600px) {
  .lading-team-name h3
  {
    font-size: .6rem;
    padding-top: 12%;
    height: 30px;
  }
  .lading-team-type p
  {
    font-size: .4rem;
    height: 20px;
  }
  .support-by
  {
    width: 28%;
    font-size: 10px;
    margin-top: -10%;
    margin-left: -1%;
  }

  .team-lading-img
  {
    width: 100%;
  }
  .lading-social {
    margin-top: -20%;
  }
  .lading-team-twiiter {
  width: 30%; 
  }
  .lading-team-twiiter-three
  {
    width: 50%;
  }
  .support-by 
  {
    height: 20px;
    margin-top: -6%;
  }
  .partners img
  {
    width: 100%;
  }
  .lading-company img 
  {
    width: 100%;
  }
  .card-details
  {
    width: 100%;
  }
  .lading-h2
  {
    font-size: 32px;
    text-align: center;
  }
  .lading-p
  {
    margin-top: 5%;
    font-size: 20px;
    text-align: center;
  }
  .lading-button
  {
    margin-top: 4%;
    font-size: 8px;
  }
  .card-details p{
    font-size: 12px;
  }
  .lading-token-img
  {
    width: 100%;
    margin-top: 20%;
  }
  .lading-token h2
  {
    font-size: 1.4rem;
  }
  .lading-token p
  {
    width: 100%;
    font-size: 0.9rem;
  }
  .partners
  {
    width: 80%;
  }
  .fuxionist_p
  {
    width: 100%;
    font-size: 18px;
    line-height: 2;
  }
  .partners h2
  {
    font-size: 1.5rem;
  }
  .lading-team-h2
  {
    font-size: 1.5rem;
  }
  .fuxionist h2
  {
    font-size: 1.5rem;
    margin-top: 10%;
  }
  .lading-header
  {
    min-height: 200px;
  }
  .lading-details h2
  {
    margin-top: 10%;
    font-size: 1.2rem;
  }
  .lading-details p
  {
    width: 100%;
    font-size: 0.6rem;
  }
  .lading-buttons
  {
    width: 60%;
    padding-bottom: 5%;
  }
  .img-header-one {
    width: 100%;
    margin: auto;
    margin-top: 50%;
    margin-bottom: 5%;
  }
  .img-header-one {
    width: 100%;
    margin: auto;
    margin-top: 50%;
    margin-bottom: 5%;
  }
  .teamone 
  {
    width: 100%;
  }
  .pp-text
  {
    font-size: 12px;
  }
  .contect-links
  {
    width: 23px;
  }
  .nav-link
  {
    font-size: 16px;
  }
  .header-one-img {
    padding-top: 0%;
  }
  .cover
  {
    width: 100%;
    height: 40vh;
    text-align: center;
    padding: 4px;
    margin: 40%;
  }
  .cover-background
  {
    background-position:right;
  }
  .partners-img{
    position: fixed;
    width: 100%;
    z-index: 1000;
    inline-size: 71;
  }
  .partners-img-one
  {
    width: 50px;
    position: sticky;
    display: grid;
    display: flexbox;
    min-width: none;
    color:#04F280;
    font-family: 'Courier New', Courier, monospace;
  }
  .img-header-two
  {
    width: 80%;
    margin-top: 40%;
  }
  .header-one-ladning
  {
    top: 2%;
  }
  .img-header-card 
  {
    padding-top: 0%;
  }
  .img-header-card
  {
    margin-top: 20%;
  }
  .img-web3-escrow
  {
    width: 100%;
  }
  .img-infuxe
  {
    width: 80%;
    margin-top: 40%;
  }
  .img-collaborate
  {
    width: 80%;
    margin-top: 40%;
  }
  .our-team-img
  {
    width: 60%;
  }
}

.soft-ware-div
{
  width: fit-content;
  height: fit-content;
  padding: 10%;
  object-fit: cover;
  margin: auto;
}
.soft-ware-div img
{
  white-space: 1;
  z-index: 2;
  min-height: max-content;
}