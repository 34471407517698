@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

.header
{
    padding-top: 10%;
    padding-bottom: 5%;
}
.header-h1
{
    text-align: center;
    font-family: Orbitron;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 35.2px */
    text-transform: uppercase;
    background: linear-gradient(90deg, #04F280 19.26%, #9DED69 80.43%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.header-p
{
    color: #FFF;
    text-align: center;
    font-family: "PP Telegraf";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.6px; /* 17.6px */
}
.form
{
    width: 100%;
    height: auto;
    padding-top: 2%;
    padding-bottom: 5%;
    flex-shrink: 0;
    border-radius: 5px;
    background: #151A22;
}
.form-input-group
{
    width: 90%;
    margin: auto;
}
.form-input
{
    background: #151A22;
    border: 1px solid #151A22;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
    margin: auto;
    color: white;
    font-size: 22px;
}
.form-input:focus
{
    border-color: #151A22;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.line-green
{
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(4, 242, 128, 0.70) 0%, rgba(157, 237, 105, 0.70) 100%);
}
.form-button
{
    width: 90%;
    margin: auto;
}
.form-button-e
{
    margin-top: 4%;
    margin-bottom: 2%;
    width: 100%;
    padding: 3%;
    background: #151A22;
    border: 1px solid;
    color: white;
    font-size: 20px;
    /* border-image: linear-gradient(90deg, #04F280 19.26%, #9DED69 80.43%); */
    border-image-slice: 1;
}
.form-button-d
{
    margin-top: 4%;
    margin-bottom: 2%;
    width: 100%;
    padding: 3%;
    background: linear-gradient(90deg, #04F280 19.26%, #9DED69 80.43%);
    /* border: 1px solid; */
    color: rgb(0, 0, 0);
    font-size: 20px;
    /* border-image: linear-gradient(90deg, #04F280 19.26%, #9DED69 80.43%); */
    border-image-slice: 1;
    border-radius: 10px 10px 10px 10px;
}

/* Mobile phone CSS */
@media only screen and (max-width: 600px)
{
    .form-input-group
    {
        width: 90%;
        margin: auto;
    }

    .form-input
    {
        background: #151A22;
        border: 1px solid #151A22;
        padding-top: 2%;
        padding-bottom: 2%;
        width: 100%;
        margin: auto;
        color: white;
        font-size: 14px;
    }
    .form-button
    {
        width: 90%;
        margin: auto;
    }
    .form-button-d
    {
        margin-top: 4%;
        margin-bottom: 2%;
        width: 100%;
        padding: 3%;
        background: linear-gradient(90deg, #04F280 19.26%, #9DED69 80.43%);
        /* border: 1px solid; */
        color: rgb(0, 0, 0);
        font-size: 20px;
        /* border-image: linear-gradient(90deg, #04F280 19.26%, #9DED69 80.43%); */
        border-image-slice: 1;
        border-radius: 10px 10px 10px 10px;
    }
    .form-button-ps
    {
        position: static;
        width: 60%;
        background: linear-gradient(90deg, rgb(0, 0, 0), 190deg rgb(40, 226, 40) );
        height: fit-content;
        min-height:max-content;
        max-width:max-content ;
        text-align: justify;
    }
}