
    .div {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      width:1250px;
      background-color: #000000;
      height: 100%;
      border:2px solid #000000;
      width: 100%;
    }
    .div-2 a
    {
      color: white;
    }
    @media (max-width: 991px) {
      .div {
        flex-wrap: flex;
      }
    }
    .imgLogo {
      aspect-ratio: 1.05;
      object-fit: auto;
      object-position: center;
      padding-right: 2%;
     
    }
    .div-2 {
      align-self: start;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      .div-2 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .div-3 {
      font-family: Poppins, sans-serif;
      align-self: stretch;
      flex-grow: 1;
      white-space: nowrap;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .div-3 {
        white-space: initial;
      }
    }
    .div-4 {
      justify-content: center;
      align-self: stretch;
      display: flex;
      gap: 5px;
      white-space: nowrap;
      margin: auto 0;
      padding: 5px;
    }
    @media (max-width: 991px) {
      .div-4 {
        white-space: initial;
      }
    }
    .div-5 {
      font-family: Poppins, sans-serif;
      flex-grow: 1;
    }
    .img-2 {
      aspect-ratio: 2.17;
      object-fit: auto;
      object-position: center;
      width: 13px;
      fill: #fff;
      margin: auto 0;
    }
    .div-6 {
      justify-content: center;
      align-self: stretch;
      display: flex;
      gap: 5px;
      white-space: nowrap;
      margin: auto 0;
      padding: 10px;
    }
    @media (max-width: 991px) {
      .div-6 {
        white-space: initial;
      }
    }
    .div-7 {
      font-family: Poppins, sans-serif;
      flex-grow: 1;
    }
    .img-3 {
      aspect-ratio: 2.17;
      object-fit: auto;
      object-position: center;
      width: 13px;
      fill: #fff;
      margin: auto 0;
    }
    .div-8 {
      font-family: Poppins, sans-serif;
      align-self: stretch;
      margin: auto 0;
    }
    .div-9 {
      font-family: Poppins, sans-serif;
      align-self: stretch;
      margin: auto 0;
    }
    .div-10 {
      font-family: Poppins, sans-serif;
      align-self: stretch;
      margin: auto 0;
    }
    .div-11 {
      font-family: Poppins, sans-serif;
      justify-content: center;
      border-radius: 20px;
      background-color: #fddd3f;
      align-self: stretch;
      flex-grow: 1;
      color: #252525;
      white-space: nowrap;
      padding: 8px 15px;
    }
    @media (max-width: 991px) {
      .div-11 {
        white-space: initial;
      }
    }
    .dropdown-menu
    {
      background-color: #000000;
    }
    .custom-navbar {
      background-color: black; 
      padding-top: 3%;
    }
    
    .custom-navbar .nav-link {
      color: white !important; 
      font-family: orbitron;
    }
  .header-text
  {
    text-align: right;
  }
  .custom-navbar {
    background-color: black;
  }

  .custom-navbar .nav-link {
    color: white !important;
  }

  .custom-navbar .navbar-toggler {
    border-color: rgb(0, 0, 0);
  }

  .custom-navbar .navbar-toggler-icon {
    background-color: white;
  }

  .custom-navbar .dropdown-menu {
    background-color: white;
    color: black;
  }

  .header-text .navbar-nav {
    margin: 0;
  }

  .header-text .navbar-nav .nav-link {
    color: white !important;
  }

  .header-text .navbar-nav .nav-item.dropdown.show .dropdown-menu {
    background-color: white;
  }

  @media (max-width: 767px) {
    .align-self-end {
      text-align: right;
    }
    .custom-navbar {
      background-color: black; 
      padding-top: 8%;
    }
  }
