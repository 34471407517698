@font-face {
  font-family: 'Jeko-Regular'; /* Name to reference the font */
  src: url('../../public/assets/FontsFree-Net-Jeko-Bold.ttf');
}

.roadmap-header-text h2
{font-size: 3rem;
font-family: orbitron;
color: white;}
.roadmap-header-text-p
{
  color: #FFF;
  text-align: right;
  font-family: Jeko-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}
p
{
  font-family: PP Telegraf;
}
.roadmap-header
{
    margin-top: 5%;
    margin-bottom: 5%;
}
.roadmap-data-header h3
{
 
  color: white;
  color: #FFF;
  text-align: center;
  font-family: "PP Telegraf";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 19.8px */
}
.roadmap-data-header p
{
  color: #FFF;
text-align: center;
font-family: "PP Telegraf";
font-size: 12px;
font-style: normal;
font-weight: 200;
line-height: 13px; /* 13.2px */
}
.header-text h2
{
    font-size: 3rem;
    font-family: orbitron;
    color: white;
}
.header-span
{
    color:#04F280 ;
}
.header-text p
{
    color: white;
    font-family: PP Telegraf;
    font-size: 1rem;
}
.roadmap
{
    margin-top: 10%;
    margin-bottom: 10%;
}
.road-map-section
{
  min-height: 400px;
}
.roadmap-div
{
  border-radius: 20px 20px 20px 20px;
  width: 100%;
  padding: 10%;
  background: #151A22;
  margin-bottom: 6%;
}
.roadmap-div p 
{
  color: #FFF;
  font-family: "PP Telegraf";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 32.4px */
}
.roadmap-div-img
{
  width: 15px;
  height: 15px;
  margin-top: -1%;
  flex-shrink: 0;
}
.data-road
{
  color: white;
  padding-top: 5%;
}

  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
    .daas-line
    {
      display: none;
    }
    .roadmap-header-text h2
    {
      font-size: 1.5rem;
      text-align: center;  
      padding-top: 10%; 
      padding-bottom: 10%;
    }
    .roadmap-header-text-p
    {
      font-size: 16px;
      text-align: center;
    }
    .roadmap-div p {
      font-size: 14px;
    }
    .roadmap-data-header
    {
      padding-top: 5%;
      padding-bottom: 5%;
    }
  }