.what-is-daas
{
    font-family: orbitron;
    margin-top: 10%;
    margin-bottom: 5%;
   
}
.what-is-daas h2
{
    font-size: 3rem;
    text-align: center;
    color: white;
}
.daas-span
{
    color: #04F280;
}
.daas-p
{
    text-align: center;
    width: 60%;
    margin: auto;
    color: white;
    font-size: 1.1rem;
    font-family: PP Telegraf;
}
.daas-line
{
    min-height: 20rem;
}
.daas-lines
{
    width: 68%;
    background-color: white;
    height: 1.2px;
    margin: auto;
}
.dass-img-one
{
    position: absolute;
    transform: translate(-50%, -50%);
}
.dass-img-two
{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dass-img-three {
    position: relative;
    left: 100%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
    .header-text h2
    {
        font-size: 1.5rem
    }
    .header-text p
    {
        font-size: 0.9rem;
        font-family: PP Telegraf;
    }
    .what-is-daas h2
    {
        font-size: 1.5rem
    }
    .daas-line
    {
        margin-top: 20%;
        
    }
    .daas-p
    {
        width: 100%;
        font-size: 0.9rem;
        font-family: PP Telegraf;
    }

    .dass-img-one
    {
        position: absolute;
        transform: translate(-50%, -50%);
        width: 10%;
    }
    .dass-img-two
    {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10%;
    }
    .dass-img-three
    {
        position: absolute;
        left: 80%;
        transform: translate(-50%, -50%);
        width: 10%;
    }

}