.contact-us
{
    padding-top: 10%;
    color: white;
    padding-bottom: 10%;
    font-family: orbitron;
}
.contact-us input , textarea
{
    border-radius: 10px;
}
.contact-us label , button
{
    margin-bottom: 1%;
    margin-top: 1%;
}
.card-details-lading-button
{
    border: 1px solid;
    border-radius: 10px;
    font-family: PP Telegraf;
    margin-top: 3%;
    margin-bottom: 3%;
    background: var(--lynx-gradient, linear-gradient(180deg, #9DED69 0%, #04F280 70.21%));
    color: black;
    /* padding: 15px; */
    /* gap: 10px;
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-family: 'Orbitron'; */
}
.contact-us-spacing
{
    margin-top: 2%;
    margin-bottom: 2%;
}
.contact-us-details
{
    padding: 2%;
    color: white;
}
.contact-us-details a 
{
    color: white;
    text-decoration: none;
}
.contact-us-details h2
{
    text-align: left;
    width: 70%;
    margin: auto;
    font-size: 2.5rem;
    color: rgba(4, 242, 128, 1);
}
.contact-us-details p
{
    padding-top: 2%;
    line-height: 2;
    font-family: PP Telegraf;
    text-align: left;
    width: 70%;
    margin: auto;
}
@media only screen and (max-width: 600px) {
    .contact-us-details p
    {
        width: 100%;
    }
}