    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    @font-face {
        font-family: 'Jeko-Medium';
        src: url('../jeko/FontsFree-Net-Jeko-Medium.ttf') format('opentype');
    }
    .dashboard-header
    {
        background: rgba(20, 23, 21, 0.48);
        width: 100%;
        height: 100vh;
    }

    .header-dashboard
    {
        width: 100%;
        height: auto;
        padding-top: 5%;

    }
    .header-dashboard-h2
    {
        color: white;
        font-size: 2rem;
        font-variant-caps: all-small-caps;
        font-weight: bold;
        font-family: orbitron;
        line-height: 120%;
    }
    .header-dashboard-p
    {
        color: white;
    }
    .span-header-h2
    {
        color: #9DED69;
    }
    .dashboard-sidebar-right
    {
        color: white;
    }
    .notifaction
    {
        padding-top: 50%;
    }
    .dashboard-connect-wallet
    {
        border: 1px solid;
        border-radius: 10px;
        font-family: PP Telegraf;
        margin-top: 3%;
        margin-bottom: 3%;
        border-radius: 8px;
        background: linear-gradient(75deg, #04F280 -49.12%, #9DED69 86.63%);
        color: black;
        width: 100%;
        height: 58px;
        padding: 8px 16px;
    }
    .headerklmklm-dashboard-h2
    {
        color: #FFF;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

    }
    .market-model-search
    {
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .dashboard-dropdown-m
    {
        padding:3% 15%;
        gap: 8px;
        border-radius: 8px;
        background: #141715;
        width: 100%;
    }
    .dashboard-dropdown-m a
    {
        color: #FAFAFA;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .dashboard-search
    {
        align-items: center;
        border-radius: 8px;
        background: #141715;
        width: 100%;
        border: 20px #141715;
        font-size: 18px;
        color: #5B5C5B;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding:0% 5%;
    }
    .dashboard-search input{
        padding:5% 10%;   
        color: #5B5C5B;  
        background: #141715;  
        border: 20px #141715; 
        width: 100%;
        color: white;
    }

    .fux-dash-labimg
    {
        padding: 1.2%;
    }
    .fux-dash-lab
    {
        color: #FFF;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .perfome-dashboard
    {
        border-radius: 14px;
        border: 1px solid #131313;
        background: rgba(20, 23, 21, 0.48);
        backdrop-filter: blur(32px);
        width: 100%;
        height: auto;
        flex-shrink: 0;
    }
    .perfome-dashboard-h2
    {
        color: rgba(251, 251, 251, 0.82);
        font-family: Jeko-Medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 5%;
    }
    .perfor-dashboard-space
    {
        padding-top: 15%;
    }
    .perfome-dashboard-box
    {
        padding-top: 5%;
        border:1px solid rgba(78, 77, 77, 0.295);
        padding: 5%;
    }
    .perfome-dashboard-box-p
    {
        color: #6A8067;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .circle-box-text-sma
    {
        color: #E3F7D3;
        font-family: Jeko-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        line-height: 2.5;
        font-weight: bolder;
    }
    .fux-dash-button-d
    {
        border-radius: 16px;
        background: linear-gradient(0deg, rgb(68 128 64) 0%, rgb(58 125 64) 100%), url(../../public/assets/images/Mesh\ gradient.png) lightgray 0px -21.333px / 100% 200% no-repeat;
        width: 100%;
        flex-shrink: 0;
        padding: 5%;
    }
    .fux-dash-button-d h3
    {
        color: #FFF;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .fux-data-p
    {
        color: #DFDFDF;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .nav-link:focus, .nav-link:hover
    {
        color: white;
    }
    .technial-analysis
    {
        padding-top: 4%;
    }
    .technial-analysis-p
    {
        color: rgba(251, 251, 251, 0.82);
        font-family: Jeko-Medium;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .technial-analysis-img
    {
        border-radius: 16px;
        /* border: 1px solid #131313; */
        /* background: rgba(20, 23, 21, 0.48); */
        backdrop-filter: blur(32px);
        width: 100%;
        height: 400px;
        flex-shrink: 0;
        background: linear-gradient(0deg, rgba(22, 27, 23, 0.30) 0%, rgba(22, 27, 23, 0.30) 100%), url(../../public/assets/images/Rectangle-4.png) rgb(0, 0, 0) 0px -21.333px / 100% 200% no-repeat;
    }
    .pred-model-data-active
    {
        width: 100%;
        padding: 5%;
        flex-shrink: 0;
        border-radius: 14px;    
        background: linear-gradient(80deg, #04F280 -52.44%, #9DED69 98.38%);
    }
    .pred-model-data-active p 
    {
        color: rgba(19, 19, 19, 0.75);
        opacity: 0.6;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .pred-model-data-active h2
    {
        color: #131313;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
    }
    .pred-model-data
    {
        width: 100%;
        padding: 5%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.199);
        border-radius: 14px;
    }
    .pred-model-data p
    {
        color: #A3C69E;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .pred-model-data h2
    {
        color: #088A4B;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .pre-model-box
    {
        width: 100%;
        height: auto;
        flex-shrink: 0; 
        border: 1px solid rgba(255, 255, 255, 0.199);
        border-radius: 14px;
    }
    .model-pre-ps
    {
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .twitter-news
    {
        width: 100%;
        height: 137px;
        flex-shrink: 0;
        border-radius: 16px;
        background: #141715;
    }
    .other-news
    {
        width: 100%;
        height: 137px;
        flex-shrink: 0;
        border-radius: 16px;
        background: #141715;
    }
    .dash-logo
    {
        height: 20vh;
    }
    .dash-logo img
    {
        padding-top: 25%;
    }
    .dash-menu-wa
    {
        height: 60vh;
    }
    .das-menu-footer
    {
        height: 30vh;
    }
    .menu-data
    {
        padding-left:15%;
    }
    .p-dash-board
    {
        color: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 15%;
    }
    .p-dash-board-span
    {
        padding: 4%;
    }
    .waller-price-update
    {
        height: 300px;
        width: 300px;
        background-color: #131313;
        color: white;
        padding: 5%;
        margin: auto;
    }
    .up
    {
        color: #F3F3F3;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top:40%;
        float: right;
    }
    .up-moodel-pre p
    {
        color: #000000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top:40%;
        float: right;
    }
    .up-moodel-pre-black p 
    {
        color: #A3C69E;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top:40%;
        float: right;
    }
    /* .perfome-dashboard-circel
    {
        margin: 15%;
    } */
    .perfome-dashboard-circel-box {
        display: block;
        margin: auto;
    }

    .progress {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
    .score-svg
    {
        padding: 5%;
    }
    .performace-circle-ss
    {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 5px solid #04F280;
    }
    .circle-box-text-sm
    {
        
        text-align: center;
        color: white;
        color: #E3F7D3;
        font-family: Jeko-Medium;
        font-size: 50px;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
    }
    .model-serty
    {
        text-decoration: double;
        height: auto;
        width: 100%;
        background-color: #04F280;
        margin: auto;
        padding: 20%;
        text-overflow: calc(30,20,10);
        padding-inline-start: 10cap;
        margin-left: auto !important;
        margin-right: auto !important;
        color: #04F280;
        text-align: justify;
        font-size: 2rem;
    }
    .model-serty-total
    {
        font-size: larger;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        padding: 20%;
        margin: 10cap;
        margin-left: auto !important;
        margin-right: auto !important;
        background-color: #04F280;
        color: white;
        text-align: justify;
        font-weight: 800;
    }
    /*
    **Stock css
    */
    .company-stock-heading
    {
        margin-top: 2%;
    }
    .company-stock-h2
    {
        color: #FFF;
        font-family: Jeko-Medium;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 2%;
    }
    .buttons-stocks
    {
        padding: 2%;
        border-radius: 16px;
        background: rgba(22, 25, 23, 0.64);
        backdrop-filter: blur(32px);
    }
    .button-stock-button-g
    {
        border-radius: 4px;
        background: linear-gradient(107deg, #378a52 -10.33%, rgba(3, 247, 129, 0.1) 106.93%);
        padding: 1%;
        width: 100%;
        height: auto;
        flex-shrink: 0;
        margin-top: 1%;
        padding: 3%;
    }
    .button-stock-button-g-p
    {
        color: #DFDFDF;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    /* .technical-model
    {
    padding-top: 2%;
    } */
    .technical-model-p-a
    {
        color: rgb(243 243 243 / 80%);
        font-family: Jeko-Medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 3;
    }
    .predication-model-stock
    {
        width: 100%;
        height: auto;
        flex-shrink: 0; 
        border-radius: 14px;
        border: 1px solid #131313;
        background: rgba(22, 25, 23, 0.64);
        backdrop-filter: blur(32px);
    }
    .predication-model-stock-price
    {
        border-radius: 14px;
        border: 1px solid #131313;
        background: rgba(22, 25, 23, 0.64);
        backdrop-filter: blur(32px);
        border-bottom: 1px solid rgba(68, 66, 66, 0.228);
        padding: 4%;
    }
    .predication-model-stock-price-h2
    {
        color: #505050;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .active
    {
        color: white;
    }
    .predication-model-stock-price-h6
    {
        color: #CBCBCB;
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .predication-model-stock-price-pp
    {
        color: white;
        padding-top: 10%;
    }
    .twitter-sentiment
    {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        border-radius: 12px;
        background: rgba(22, 25, 23, 0.64);
        backdrop-filter: blur(32px);
    }
    .twitter-sentiment-h2
    {
        color: #CBCBCB;
        padding: 5%;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal
    }
    .twitter-sentiment-img-ss
    {
        width: 200px;
    }
    .Institutional-Target-div
    {
        margin-top: 5%;
        width: 100%;
        height: 240px;
        flex-shrink: 0;
        border-radius: 12px;
        background: rgba(22, 25, 23, 0.64);
        backdrop-filter: blur(32px);
    }
    .Institutional-Target-div::before
    {
        color: #04F280;
    }
    .insider-trading
    {
        width: 100%;
        height: 120px;
        flex-shrink: 0;
        background: url(../../public/assets/images/Rectangle26.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;
        padding: 10%;
    }
    .Senate-Trading
    {
        width: 100%;
        height: 120px;
        flex-shrink: 0;
        background:url(../../public/assets/images/Rectangle-31.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;
        padding: 10%;
    }
    .insider-trading-h3
    {
        color: #CBCBCB;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .Twitter-Sentiment-img
    {
        width: 200px;
        padding-bottom: 10%;
    }
    .company-stock-price
    {
        color: #FFF;
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .technial-analysis-img svg
    {
        width: 100%;
        object-fit: contain;
    }
    .company-stock-span
    {
        padding: 1%;
        font-size: 10px;
    }
    .twitter-sentiment-img-div {
        position: relative;
    }
    .twitter-sentiment-img-arrow {
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
    }
    .twitter-sentiment-img-arrow-img {
        height: 50px;
        transform: rotate(300deg);
        transform-origin: center;
        position: absolute;
    }
    .twitter-sentiment-img-arrow-span {
        color: white;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
@media only screen and (max-width: 800px) {
    .dashboard-header{
        display: none;
    }
    .dashboard-dropdown-m {
        margin-bottom: 5%;
    }
    .fux-dash-button-d
    {
        margin-bottom: 5%;
    }
    .twitter-news
    {
        margin-bottom: 5%;
    }
    .other-news
    {
        margin-bottom: 5%;
    }
    .technial-analysis-img
    {
        margin-bottom: 5%;
    }
    .technial-analysis-p
    {
        text-align: center;
        margin-bottom: 5%;
    }
    .fux-dash-lab
    {
        text-align: center;
        margin-top: 5%;
        margin-bottom: 5%;
        font-size: 20px;
    }
    .perfome-dashboard-box-p {
        font-size: 16px;
        color: white;
    }
    .button-stock-button-g
    {
        margin-bottom: 5%;
    }
    .technial-analysis-img
    {
        height: auto;
    }
    .up {
        padding-top: 15%;
    }
    .up-moodel-pre p
    {
        padding-top: 18%;
    }
    .button-stock-button-g
    {
        background: linear-gradient(107deg, #000000 -10.33%, rgba(14, 230, 126, 0.782) 106.93%);
        padding: 2%;
        margin: 3%;
    }
    .button-stock-button-g-p {
        color: #ffffff;
        font-weight: bolder;
    }
}