html,
body,
#root {
  height: 100vh;
  margin: 0;
  padding: 0;
}

#app {
  background-color: #000000;
  height: auto;
  background-repeat: repeat-y;
}