.about-header
{
    padding-top: 5%;
    padding-bottom: 1%;
}
.about_header_img
{
    width: 80%;
    margin: auto;
    margin-left: 10%;
}
.about-img-s
{
    width: 70%;
    float: right;
}
.about-who-are
{
    margin-top: 5%;
    margin-bottom: 5%;
}
.about-who-are h2 
{
    font-size: 3rem;
    color: #9DED69;
    text-align: center;
    font-family: orbitron;
}
.about-who-are p 
{
    font-size: 1.3rem;
    width: 60%;
    margin: auto;
    text-align: center;
    color: white;
    font-family: PP Telegraf;
}
.mission-ves
{
    margin-bottom: 5%;
    font-family: orbitron;
}
.mission-div
{
    width: 70%;
    margin: auto;
    background-color: #151A22;
    text-align: center;
    padding: 6%;
    border-radius: 20px 20px 20px 20px;
}
.mission-h2
{
    color: #04F280;
    font-size: 2rem;   
    padding-top: 3%;
}
.mission-div p
{
    padding: 2%;
    color: white;
    font-size: 1.1rem;
    font-family: PP Telegraf;
}
.core-principal
{
    min-height: 200px;
}
.core-principal-h2
{
    font-size: 3rem;
    color: #9DED69;
    font-family: orbitron;
    text-align: center;
}
.core-principal-div-2
{
    background-color: #151A22;
    width: 100%;
    margin-top: 20%;
    margin-bottom: 10%;
    text-align: center;
    padding: 10%;
    font-family: orbitron;
    height: 450px;
    border-radius: 20px 20px 20px 20px;
    
}
.core-principal-div-2 h2
{
    padding-top: 20%;
    padding-bottom: 10%;
    color: #04F280;
    font-size: 24px;
    width: 100%;
    margin: auto;
    text-align: left; 
    font-size: 24px; 
}
.core-principal-div-2 p
{
    /* padding: 5%; */
    color: white;
    font-size: 16px;
    font-family: PP Telegraf;
    text-align: left; 
    font-size: 20px; 
}
.core-principal-div
{
    background-color: #151A22;
    width: 100%;
    margin-top: 20%;
    margin-bottom: 10%;
    text-align: center;
    padding: 10%;
    font-family: orbitron;
    height: 300px;
    border-radius: 20px 20px 20px 20px;
}
.core-principal-img
{
    height: 60px;
    width: 60px;
    padding: 6%;
    margin: auto;
}
.mission-principal-img
{
    height: 40px;
    width: 40px;
}

.core-principal-div h2
{
    color: #04F280;
    font-size: 24px;
    width: 80%;
    margin: auto;   
}
.core-principal-div p
{
    padding: 5%;
    color: white;
    font-size: 16px;
    font-family: PP Telegraf;
}

.lading-about-button {
    border-radius: 10px;
    font-family: orbitron;
    margin-top: 3%;
    margin-bottom: 3%;
    background: linear-gradient(to right, rgb(0, 255, 128), rgb(125, 245, 21));
    color: black;
}
@media only screen and (max-width: 600px) {
    .lading-about-button {
        margin: auto;
        margin-top: 1%;
        font-size: 12px;
        text-align: center;
    }
    .div-button-about
    {
        margin-top: 4%;
        text-align: center;
    }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .mission-h2
    {
        font-size: 1.5rem;
    }
    .mission-div
    {
        width: 100%;
    }
    .core-principal-div h2
    {
        font-size: 15px;
        width: 100%;
    }
    .core-principal-div
    {
        height: 400px;
        padding: 5%;
    }
    .core-principal-div p
    {
        padding: 5%;
    }
    .about_header_img
    {
        margin-top: 20%;
    }
    .about-who-are p
    {
        width: 80%;
    }
    .lading-p {
        font-size: 1.5rem;
    }
  }


@media only screen and (max-width: 600px) {
    .about-who-are h2
    {
        font-size:1.5rem;
    }
    .about-who-are p
    {
        width: 100%;
        font-size: 0.9rem;
    }
    .mission-div
    {
        width: 100%;
        margin-bottom: 5%;
        padding: 5%;
    }
    .mission-h2
    {
        font-size: 1.5rem;
    }
    .mission-div p
    {
        padding: 1%;
        font-size: 0.9rem;
    }
    .core-principal-h2
    {
        font-size: 28px;
    }
    .core-principal-div
    {
        padding: 0%;
        height: auto;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .core-principal-div h2
    {
        width: 100%;
        font-size: .9rem;
        padding-top: 3%;
    }
    .core-principal-div p
    {
        padding: 5%;
        font-size: 0.9rem;
    }
    .about_header_img {
        width: 60%;
        margin: auto;
        padding-top: 10%;
    }
    .about-image-div
    {
        text-align: center;
        padding: 5%;
    }
    .about_header_img
    {
        margin-left: 0%;
    }
    .core-principal-div-2{
        padding: 10%;
        height: auto;
        margin-top: 5%;
        margin-bottom: 5%;
        height: 400px;
       
    }
    .core-principal-div-2 h2
    {
        width: 100%;
        font-size: 1.5rem;
        font-size: 24px;
    }
    .core-principal-div-2 p
    {
        font-size: 20px;
        text-align: left;
    }
    .about-img-s
    {
        width: 100%;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .core-principal-div img
    {
        padding-top: 5%;
        padding-bottom: 5%;
    }
}